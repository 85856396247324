window.lang = 'fi';

function initForm() {
    function init() {
        const error_inputs = document.querySelectorAll('.error input') as NodeListOf<HTMLInputElement>;
        for (const input of error_inputs) {
            input.setCustomValidity('Virheellinen arvo');
            input.addEventListener('input', function(e: Event) {
                const target = e.currentTarget as HTMLInputElement;
                target.setCustomValidity('');
                const control_group = target.closest('.pure-control-group') as HTMLDialogElement;

                if (control_group != null) {
                    control_group.classList.remove('error');
                }

            }, {once: true})
        }
    }

    if (document.readyState != 'loading') {
        init();
    } else {
        document.addEventListener('DOMContentLoaded', init);
    }
}

initForm();
